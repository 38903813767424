import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientGroups,
  setClientPbiResources,
  setWholeData,
  setWholePbiResources,
  setWholePbiResourcesByType,
  setActivityLoader
} from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import ClientWorkspacesAccordian from "./ClientWorkspacesAccordian";
import AccordionArrow from "../../../assets/theme/images/down-arrow.svg";
import {
  searchClientWorkspaces,
  searchClientPbiResources,
} from "../../../services";

function CustomToggle({ children, eventKey, clientId }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    // console.log("totally custom!", clientId)

    getClientGroups(clientId)
  );

  async function getClientGroups(clientId) {
    dispatch(
      setActivityLoader({
        activityLoader: true,
      })
    );
    if (
      general?.wholeData[clientId] &&
      general?.wholeData[clientId]["workapaces"]
    ) {
      getClientPbiResources(clientId);
      dispatch(
        setActivityLoader({
          activityLoader: false,
        })
      );
      return;
    }
    let user = false;
    user = {
      token: userData.accessToken,
    };
    let ignore = false;
    const response = await searchClientWorkspaces(clientId, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      dispatch(setClientGroups({ clientGroups: response.data.workspaces }));

      let datasets = [];
      datasets[clientId] = {
        ...datasets[clientId],
        workapaces: response.data.workspaces,
      };
      setShowLoader(false);      
      dispatch(setWholeData({ wholeData: datasets }));
      getClientPbiResources(clientId);
      console.log('wholeData', general.wholeData);
    }
    dispatch(
      setActivityLoader({
        activityLoader: false,
      })
    );
  }
  async function getClientPbiResources(clientId) {
    console.log('wholePbiResources', general.wholePbiResources);
    if (
      general?.wholePbiResources[clientId] &&
      general?.wholePbiResources[clientId]["pbiResources"]
    ) {
      dispatch(
        setActivityLoader({
          activityLoader: false,
        })
      );
      return;
    }
    let user = false;
    user = {
      token: userData.accessToken,
    };
    let ignore = false;
    const response = await searchClientPbiResources(clientId, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      
      setShowLoader(false);

      const jsonArray = response.data.pbiResources;
      if (jsonArray.length > 0) {
        const groupedByWorkspaceId = jsonArray.reduce((accumulator, item) => {
          // Get the category of the current item

          const workspaceId = item.workspaceId;

          // Initialize the category array if not already initialized

          if (!accumulator[workspaceId]) {
            accumulator[workspaceId] = [];
          }

          // Add the current item to its category array

          accumulator[workspaceId].push(item);

          return accumulator;
        }, {});
        let datasets = [];
        datasets[clientId] = {
          ...datasets[clientId],
          pbiResources: groupedByWorkspaceId,
        };
        
        dispatch(setWholePbiResources({ wholePbiResources: datasets }));

        //group by type
      
      const reportData = jsonArray.reduce((accumulator, item) => {
        // Get the category of the current item

        const type = item.type;
        
        // Initialize the category array if not already initialized

        if (!accumulator[type]) {
          accumulator[type] = [];
        }

        // Add the current item to its category array

        accumulator[type].push(item);

        return accumulator;
      }, {});
      let dataByType = [];
      dataByType[clientId] = {
          ...dataByType[clientId],
          pbiResources: reportData,
        };

        dispatch(
          setWholePbiResourcesByType({ wholePbiResourcesByType: dataByType })
        );
        
      }
    }
  }

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
function ClientAccordion() {
  console.log("Client Accordion");
  const general = useSelector((state) => state.general);
  return (
    <Accordion defaultActiveKey="0" className="clients-accordion-main">
      {general?.clientList.length > 0 &&
        general?.clientList.map((item) => (
          <Card key={item.id}>
            <Card.Header>
              <CustomToggle eventKey={item.id} clientId={item.id}>
                {item.name} <img src={AccordionArrow} alt="" />
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={item.id}>
              <Card.Body>
                <ClientWorkspacesAccordian clientId={item.id} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
    </Accordion>
  );
}

export default ClientAccordion;
