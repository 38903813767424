import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  websiteName: false,
  activityLoader: false,
  userFavourites: false,
  userData:false,
  activeMenu:'Dashboard',
  menuOpenClose:false,
  clientList:false,
  clientGroups:false,
  clientReports:false,
  clientDataset:false,
  clientDashboard:false,
  clientReportPages:false,
  clientDashboardTiles:false,
  burggerMenuOpenClose:false,
  clientPbiResources:false,
  clientWorkspaces:false,
  widgetDataset:false,
  isFullScreen:false,
  fullwidth:false,
  powerBIEmbedResource:false,
  powerBIEmbedMobileView:false,
  powerBIEmbedLayoutType :1,
  qnaQuery:'',
  wholeData:[],
  wholePbiResources:false,
  wholePbiResourcesByType:false,
  typewiseResources:false,
  typewiseResourcesBackup:false,
  logoImage:false,
  bannerImage:false,
  succesMessage:false,
  errorMessage:false,
  metaTitle:false,
  metaDescription:false,
  metaKeywords:false,
  metaImage:false,
  metaUrl:false,
  favicon:false,
  primaryColor:'#1ba0d8',
  secondaryColor:'#1595cb',
  
}

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
      setWebsiteName(state, action) {
        state.websiteName = action.payload.websiteName;
      },
      setPrimaryColor(state, action) {
        state.primaryColor = action.payload.primaryColor;
      },
      setSecondaryColor(state, action) {
        state.secondaryColor = action.payload.secondaryColor;
      },
      setMetaTitle(state, action) {
        state.metaTitle = action.payload.metaTitle;
      },
      setMetaDescription(state, action) {
        state.metaDescription = action.payload.metaDescription;
      },
      setMetaKeywords(state, action) {
        state.metaKeywords = action.payload.metaKeywords;
      },
      setMetaImage(state, action) {
        state.metaImage = action.payload.metaImage;
      },
      setMetaUrl(state, action) {
        state.metaUrl = action.payload.metaUrl;
      },
      setFavicon(state, action) {
        state.favicon = action.payload.favicon;
      },
      setSuccesMessage(state, action) {
        state.succesMessage = action.payload.succesMessage;
      },
      setErrorMessage(state, action) {
        state.errorMessage = action.payload.errorMessage;
      },
      setActivityLoader(state, action) {
        state.activityLoader = action.payload.activityLoader;
      },
      setUserData(state, action) {
        state.userData = action.payload.userData;
      },
      setUserFavourites(state, action) {
        state.userFavourites = action.payload.userFavourites;
      },
      setFullScreen(state, action) {
        state.isFullScreen = action.payload.isFullScreen;
      },
      setFullwidth(state, action) {
        state.fullwidth = action.payload.fullwidth;
      },
      setActiveMenu(state, action) {
        state.activeMenu = action.payload.activeMenu;
      },
      setMenuOpenClose(state, action) {
        state.menuOpenClose = action.payload.menuOpenClose;
      },
      setClientList(state, action) {
        state.clientList = action.payload.clientList;
      },
      setClientGroups(state, action) {
        state.clientGroups = action.payload.clientGroups;
      },
      setClientWorkspaces(state, action) {
        state.clientWorkspaces = action.payload.clientWorkspaces;
      },
      setClientReports(state, action) {
        state.clientReports = action.payload.clientReports;
      },
      setClientDashboard(state, action) {
        state.clientDashboard = action.payload.clientDashboard;
      },
      setClientDataset(state, action) {
        state.clientDataset = action.payload.clientDataset;
      },
      setClientReportPages(state, action) {
        state.clientReportPages = action.payload.clientReportPages;
      },
      setClientDashboardTiles(state, action) {
        state.clientDashboardTiles = action.payload.clientDashboardTiles;
      },
      setBurggerMenuOpenClose(state, action) {
        state.burggerMenuOpenClose = action.payload.burggerMenuOpenClose;
      },
      setClientPbiResources(state, action) {
        state.clientPbiResources = action.payload.clientPbiResources;
      },
      setTypewiseResources(state, action) {
        state.typewiseResources = action.payload.typewiseResources;
      },
      setTypewiseResourcesBackup(state, action) {
        state.typewiseResourcesBackup = action.payload.typewiseResources;
      },
      setWidgetDataset(state, action) {
        state.widgetDataset = action.payload.widgetDataset;
      },
      setQnaQuery(state, action) {
        state.qnaQuery = action.payload.qnaQuery;
      },
      setBannerImage(state, action) {
        state.bannerImage = action.payload.bannerImage;
      },
      setLogoImage(state, action) {
        state.logoImage = action.payload.logoImage;
      },
      setPowerBIEmbedLayoutType(state, action) {
        state.powerBIEmbedLayoutType = action.payload.powerBIEmbedLayoutType;
      },
      setPowerBIEmbedResource(state, action) {
        state.powerBIEmbedResource = action.payload.powerBIEmbedResource;
      },
      setPowerBIEmbedMobileView(state, action) {
        state.powerBIEmbedMobileView = action.payload.powerBIEmbedMobileView;
      },
      setWholeData(state, action) {
        state.wholeData = { ...state.wholeData, ...action.payload.wholeData};
      },
      setWholePbiResources(state, action) {
        state.wholePbiResources = { ...state.wholePbiResources, ...action.payload.wholePbiResources};
      },
      setWholePbiResourcesByType(state, action) {
        state.wholePbiResourcesByType = { ...state.wholePbiResourcesByType, ...action.payload.wholePbiResourcesByType};
      },
      ClearAll(state, action) {
        return initialState;
      }
     
    }
})

// Action creators are generated for each case reducer function
export const { 
                setWebsiteName, 
                setActivityLoader ,
                setUserData,
                setActiveMenu,
                setMenuOpenClose,
                setClientList,
                setClientGroups,
                setClientReports,
                setClientDashboard, 
                setClientDataset,
                setClientReportPages,
                setClientDashboardTiles,
                setBurggerMenuOpenClose,
                setClientPbiResources,
                setWholeData,
                setWholePbiResources,
                setWholePbiResourcesByType,
                setClientWorkspaces,
                setWidgetDataset,
                setQnaQuery,
                setFullScreen,
                setPowerBIEmbedResource,
                setPowerBIEmbedMobileView,
                setPowerBIEmbedLayoutType,
                setUserFavourites,
                setTypewiseResources,
                setTypewiseResourcesBackup,
                setLogoImage,
                setBannerImage,
                setSuccesMessage,
                setErrorMessage,
                setFullwidth,
                setMetaTitle,
                setMetaDescription,
                setMetaKeywords,
                setMetaImage,
                setMetaUrl,            
                setFavicon,     
                setPrimaryColor,  
                setSecondaryColor,   
                ClearAll       
             } = generalSlice.actions

export default generalSlice.reducer